/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { Toast } from '../../enum/toast.enum';

const key = 'updatable';

type ToastPayload = string | undefined;
interface Toasts {
  loadingCounter: number;
}

const initialState: Toasts = {
  loadingCounter: 0,
};

const toasts = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<ToastPayload>) {
      if (!state.loadingCounter)
        message.loading({
          content: action.payload || Toast.loading,
          key,
          duration: 0,
        });
      state.loadingCounter += 1;
    },
    finishLoading(state, action: PayloadAction<ToastPayload>) {
      state.loadingCounter -= 1;
      if (!state.loadingCounter)
        message.success({
          content: action.payload || Toast.ok,
          key,
          duration: 2,
        });
    },
    throwLoading(state, action: PayloadAction<ToastPayload>) {
      state.loadingCounter -= 1;

      message.error({
        content: action.payload || Toast.error,
        duration: 2,
        key: state.loadingCounter ? undefined : key,
      });
    },
  },
});

export const { startLoading, finishLoading, throwLoading } = toasts.actions;

export default toasts.reducer;
