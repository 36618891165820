import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AbilityContext } from '../components/Can';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { Routes } from '../enum/routes.enum';
import { useTypedSelector } from '../store/store';
import { routes } from './routes';

export const Router: React.FC = () => {
  const { authorized } = useTypedSelector(state => state.user);
  const ability = useContext(AbilityContext);

  const router = routes.map(
    ({ link, component, exact, authProtection = true }) => {
      if (authProtection) {
        return (
          <ProtectedRoute
            exact={exact}
            key={link}
            condition={authorized}
            path={link}
            component={component}
          />
        );
      }

      return (
        <ProtectedRoute
          exact={exact}
          key={link}
          condition={!authorized}
          path={link}
          redirect={Routes.Profile}
          component={component}
        />
      );
    },
  );
  return (
    <Switch>
      {router}
      <Route path="/">
        <Redirect
          to={
            ability.can('view', Routes.Objects)
              ? Routes.Objects
              : Routes.Profile
          }
        />
      </Route>
    </Switch>
  );
};
