export enum ApiRoutes {
  objects = 'building',
  objectGroups = 'buildinggroup',
  objectAnalytic = '/building/analytics',
  objectGanttChart = '/building/gantt-chart',
  auth = 'user/login',
  user = 'user',
  prescriptions = 'check-list',
  renouncement = 'check-list/renouncement',
  remarks = 'check-list/marker',
  marker = 'check-list/marker/status',
  contractors = 'contractor',
  works = 'work',
  fileUpload = 'file/upload',
  objectFile = 'file/building',
  contractorsFile = 'file/contractors',
  handbook = 'handbook/building',
  snipsById = 'handbook/building/work',
  responsibleAutocomplete = 'check-list/contractor_representative',
  demandDescriptionAutocomplete = 'check-list/demands',
  floors = 'check-list/floors',
  names = 'contractor/names',
  analytics = 'building/analytics',
  roles = 'user/roles',
  createUser = 'user/create',
  historyActions = 'history',
  push = 'push',
  token = 'push/token',
  testPush = 'push/send',
  readings = 'push/readings',
  gpr = 'gpr',
  gprItems = 'gpr-items',
  gprTemplate = 'gpr-template',
  gprItemFile = 'file/gpr-items',
}
