export enum Title {
  Objects = 'Объекты',
  AddObject = 'Добавить объект',
  AddObjectGroup = 'Добавить проект',
  Contractors = 'Подрядные организации',
  MyContractors = 'Карточка организации',
  Prescriptions = 'Строительный контроль',
  AddPrescription = 'Создание проверки',
  Analytics = 'Аналитика',
  Handbook = 'Справочник',
  Users = 'Пользователи',
  Profile = 'Профиль',
  Login = 'Авторизация',
  Remarks = 'Замечания',
  ContractorForm = 'Добавить подрядную организацию',
  UserForm = 'Добавить пользователя',
  UserProfile = 'Просмотр пользователя',
  HistoryActions = 'История изменений',
  GPR = 'График производства работ',
  AddGPR = 'Создать график производства работ',
}
