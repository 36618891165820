/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NavigationBar {
  name: string;
  editable: boolean;
  customBack: boolean;
  customBackPressed: boolean;
}

const initialState: NavigationBar = {
  name: 'Загрузка...',
  editable: false,
  customBack: false,
  customBackPressed: false,
};

const navbar = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setNavbarName(state, action: PayloadAction<string>) {
      state.name = action.payload;
      state.customBack = false;
      state.customBackPressed = false;
    },
    setNavbarNameWithCustomBack(state, action: PayloadAction<string>) {
      state.name = action.payload;
      state.customBack = true;
      state.customBackPressed = false;
    },
    setNavbarEditable(state, action: PayloadAction<boolean>) {
      state.editable = action.payload;
    },
    setCustomBackPressed(state,action: PayloadAction<boolean> ) {
      state.customBackPressed = action.payload;
    }
  },
});

export const { setNavbarName, setNavbarEditable, setNavbarNameWithCustomBack, setCustomBackPressed } = navbar.actions;

export default navbar.reducer;
