/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from '../../api/api-routes.enum';
import { AppThunk } from '../store';
import { api } from '../../api';
import { GetAllPushResponse } from '../../api/responses/get-all-push-response';
import { AppPush } from '../../types/app-push-interface';
import { finishLoading, startLoading, throwLoading } from './toasts';

interface State {
  items: AppPush[] | null;
}

const initialState: State = {
  items: null,
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchItemsStart(state) {
      state.items = null;
    },
    fetchItemsSuccess(state, action: PayloadAction<any>) {
      state.items = action.payload;
    },
    readItemStart() {},
    readItemSuccess(state, action: PayloadAction<any>) {
      if(state.items){
        state.items = state.items.map(item => {
          if (item.id === action.payload) {
            return {...item, is_read: 1};
          }

          return item;
        })
      }
    },
  },
});

export const { fetchItemsStart, fetchItemsSuccess, readItemSuccess } = notifications.actions;

export default notifications.reducer;

export const fetchItems = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    dispatch(fetchItemsStart())
    const { data } = await api.get<GetAllPushResponse>(ApiRoutes.push)
    dispatch(fetchItemsSuccess(data));
    dispatch(finishLoading());
  } catch (err) {
    dispatch(throwLoading(err));
  }
}

export const readItem = (id: number): AppThunk => async dispatch => {
  try {
    await api.post<any>(`${ApiRoutes.push}/${id}/reading`)
    dispatch(readItemSuccess(id));
  } catch (err) {
    dispatch(throwLoading(err));
  }
}

