export const normalizePath = (path: string) => {
  let normalized: string = path.replace(/\/+/g, '/');

  if (normalized[normalized.length - 1] === '/')
    normalized = normalized.slice(0, -1);

  if (normalized[0] === '/') normalized = normalized.slice(1);

  return normalized;
};
