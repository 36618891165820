import React, { useEffect, useState } from 'react';
import { IconButton, Popover, Typography } from '@material-ui/core';
import {
  Notifications as NotificationsIcon,
  FiberManualRecord as FiberManualRecordIcon,
  NotificationsOff as NotificationsOffIconIcon,
} from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemsWithFormattedDate, selectUnreadItems } from '../../../store/selectors/notifications';
import { fetchItems, readItem } from '../../../store/slices/notifications';
import { AppPush } from '../../../types/app-push-interface';

const Notifications = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const items: AppPush[] = useSelector(selectItemsWithFormattedDate);
  const unreadItems: AppPush[] = useSelector(selectUnreadItems);

  const open = !!anchorEl;

  useEffect(() => {
    dispatch(fetchItems())
  }, [dispatch])

  return (
    <>
      <IconButton
        color={unreadItems?.length ? 'secondary' : 'default'}
        onClick={e => setAnchorEl(e.currentTarget)}
        disabled={!items?.length}
      >
        {items?.length ?
          <>
            <NotificationsIcon/>
            <Typography
              variant="body2"
            >
              {unreadItems?.length || 0}
            </Typography>
          </> :
          <NotificationsOffIconIcon />
        }
        </IconButton>

      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items?.length &&
        <List>
          {items.map((item, index) =>
            <>
              <ListItem>
                <ListItemIcon>
                  <IconButton disabled={!!item.is_read} onClick={() => (dispatch(readItem(item.id)))}>
                    <FiberManualRecordIcon color={!item.is_read ? 'secondary' : 'action'}/>
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  secondary={
                    <>
                      <Typography>
                        {item.description}
                      </Typography>

                      <Typography>
                        {item.updated_at}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {index !== items.length - 1 && <Divider />}
            </>
          )}
        </List>
        }
      </Popover>
    </>
  );
};

export default Notifications;