/* eslint-disable camelcase */
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/pictures/logo.png';
import { Routes } from '../../enum/routes.enum';
import { routes } from '../../router/routes';
import { setSideBarSm } from '../../store/slices/sidebar';
import { useTypedDispatch, useTypedSelector } from '../../store/store';
import { Can } from '../Can';
import img from './img/Picture1.jpg';
import styles from './Sidebar.module.scss';

export const SideBar: React.FC = () => {
  const active = useTypedSelector(state => state.sidebar.active);
  const activeSm = useTypedSelector(state => state.sidebar.activeSm);
  const { userData } = useTypedSelector(state => state.user);
  const userProfileName = `${userData?.last_name} ${userData?.first_name}`;
  const userContractorId = userData?.contractor_id;
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();

  const onCloseSm = () => {
    dispatch(setSideBarSm(false));
  };

  const links = routes.map(({ title, link, sidebar, icon, iconPath }) => {
    if (sidebar) {
      let activeLink = false;
      if (pathname.includes(link)) activeLink = true;
      let linkTo = link.toString();
      if(link === Routes.ContractorProfile){
        if(userContractorId){
          linkTo = linkTo.replace(':id', String(userContractorId));
        }
        else {
          return;
        }
      }
      return (
        <Can do="view" on={link} key={title}>
          <Link to={linkTo}>
            <ListItem
              button
              className={
                activeLink ? `${styles.item} ${styles.itemActive}` : styles.item
              }
            >
              <ListItemIcon
                className={
                  activeLink
                    ? `${styles.item} ${styles.itemInactive}`
                    : styles.item
                }
              >
                {icon}
                {iconPath && <img src={iconPath} alt="icon" />}
              </ListItemIcon>
              <ListItemText
                primary={link === Routes.Profile ? userProfileName : title}
              />
            </ListItem>
          </Link>
        </Can>
      );
    }

    return null;
  });

  return (
    <>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          open={active}
          classes={{ paper: styles.sidebar }}
          variant="persistent"
        >
          <List>
            <img src={logo} alt="logo" className={styles.image} />
            {links}
          </List>
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="css">
        <Drawer
          anchor="left"
          open={activeSm}
          onClose={onCloseSm}
          classes={{ paperAnchorLeft: styles.sidebarSm }}
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
        >
          <List onClick={onCloseSm}>
            <Link to="/">
              <ListItem button>
                <img src={logo} alt="logo" className={styles.image} />
              </ListItem>
            </Link>
            {links}
          </List>
        </Drawer>
      </Hidden>
    </>
  );
};
