export enum Toast {
  loading = 'Загрузка...',
  ok = 'Загрузка завершена',
  error = 'Произошла ошибка. Пожалуйста, перезагрузите страницу',
  status = 'Статус изменен',
  responsibleAutocompleteError = 'Ошибка загрузки предыдущих представителей',
  demandsDescriptionAutocompleteError = 'Ошибка загрузки предыдущих описаний',
  snipByWorkIdError = 'Ошибка загрузки СНиП для данной работы',
  getObjectsError = 'Ошибка загрузки объектов',
  getObjectError = 'Ошибка загрузки объекта',
  deleteObjectError = 'Ошибка удаления объекта',
  getContractorsError = 'Ошибка загрузки подрядных организаций',
  deleteObjectGroupError = 'Ошибка удаления проекта',
  deleteContractorError = 'Ошибка удаления подрядной организации',
  getWorksError = 'Ошибка загрузки видов работ',
  getFilesError = 'Ошибка загрузки файлов',
  sendEmailError = 'Ошибка отправки на email',
  postPrescriptionError = 'Ошибка создания проверки',
  getPrescriptionsError = 'Ошибка получения проверок',
  getPrescriptionError = 'Ошибка загрузки проверки',
  getPrescriptionSchemaError = 'Ошибка получения схемы проверки',
  getPrescriptionPdfError = 'Ошибка получения файла проверки',
  getPrescriptionRenouncementPdfError = 'Ошибка получения файла отказа',
  putPrescriptionError = 'Ошибка обновления проверки',
  deletePrescriptionError = 'Ошибка удаления проверки',
  postMarkersStatuses = 'Ошибка обновления статуса',
  getNamesError = 'Ошибка загрузки представителей подрядных организаций',
  getAnalyticsError = 'Ошибка загрузки аналитики',
  getRolesError = 'Ошибка получения ролей',
  postUserError = 'Ошибка создания пользователя',
  deleteUserError = 'Ошибка удаления пользователя',
  resetPasswordError = 'Неудалось сбросить пароль',
  getHistoryActionsError = 'Ошибка загрузки истории изменений',
  getRenouncementError = 'Ошибка загрузки отказа от подписания КС',
  postTokenError = 'Ошибка отправки токена для сервиса push уведомлений',
  getTokenError = 'Ошибка получения токена для сервиса push уведомлений',
  getPushError = 'Ошибка получения уведомлений',
  readMessageError = 'Ошибка изменеия статуса уведомления',
  offlineMode = 'Вы находитесь в оффлайн режиме',
  getGprError = 'Ошибка загрузки графика производства работ',
}
