import { Alert } from '@material-ui/lab';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { resetNewPrescription } from '../../store/slices/prescriptions';
import { useTypedDispatch, useTypedSelector } from '../../store/store';

export const UncommittedRequestsNotification: React.FC = () => {
  const dispatch = useTypedDispatch();

  const { newPrescriptionData, uncommittedRequest } = useTypedSelector(
    state => state.prescriptions,
  );
  const online = useTypedSelector(state => state.offline.online);
  const location = useLocation();

  useEffect(() => {
    if (newPrescriptionData) {
      message.success(<span>Проверка успешно сохранена</span>, 30, () => {
        dispatch(resetNewPrescription());
      });
    }
  }, [dispatch, location.pathname, newPrescriptionData]);

  /* const newPrescriptionAlert = () => (
    <Alert
      color="success"
      onClose={() => dispatch(resetNewPrescription())}
    >
      <span>
        Предписание <b>{newPrescriptionData.id}</b> успешно сохранено
      </span>
    </Alert>
  ); */

  const uncommittedRequestAlert = () => (
    <Alert color="warning">
      Данные сохранены локально. Подключитесь к сети для отправки
    </Alert>
  );

  return (
    <>
      {/* {newPrescriptionData && newPrescriptionAlert()} */}
      {!online && uncommittedRequest && uncommittedRequestAlert()}

      <br />
    </>
  );
};
